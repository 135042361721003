// Reset buttons
%RESET-BUTTON {
    background-color: transparent;
    border: none;
    outline: none;
    padding: 0;

    -webkit-tap-highlight-color: transparent;
}

%DEFAULT-INPUT {
    background-color: var(--white);
    color: var(--black);
    border: none;
    text-decoration: none;
    border-radius: 0;
    outline: none;
    padding: 0;
    -webkit-tap-highlight-color: transparent;
    position: relative;
    border-bottom: 1px solid var(--grey-1);
    width: 100%;
    padding: 0 to-rem($F5);
    box-sizing: border-box;

    &:focus {
        box-shadow: none;
        outline: none;
        color: var(--accent);
        border-color: var(--accent);
    }

    // Remove webkit autofill background
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
        box-shadow: 0 0 0 120px var(--white) inset !important;
        color: var(--black);
        -webkit-text-fill-color: var(--black);
    }

    &:placeholder-shown,
    &::placeholder {
        color: var(--grey-3);
        border-color: var(--grey-2);
    }
}

%MAX-AREA {
    width: 100%;
    position: relative;
    box-sizing: border-box;
    padding: 0 to-rem(calc(($un-272 - $grid-max-area-272) / 2)) 0;

    @include layout-320() {
        padding: 0 to-rem(calc(($un-320 - $grid-max-area-320) / 2)) 0;
    }

    @include layout-375() {
        padding: 0 to-rem(calc(($un-375 - $grid-max-area-375) / 2)) 0;
    }

    @include layout-414() {
        padding: 0 to-rem(calc(($un-414 - $grid-max-area-414) / 2)) 0;
    }

    @include layout-540() {
        padding: 0 to-rem(calc(($un-540 - $grid-max-area-540) / 2)) 0;
    }

    @include layout-768() {
        padding: 0 to-rem(calc(($un-768 - $grid-max-area-768) / 2)) 0;
    }

    @include layout-960() {
        padding: 0 to-rem(calc(($un-960 - $grid-max-area-960) / 2)) 0;
    }

    @include layout-1024() {
        padding: 0 to-rem(calc(($un-1024 - $grid-max-area-1024) / 2)) 0;
    }

    @include layout-1280() {
        padding: 0 to-rem(calc(($un-1280 - $grid-max-area-1280) / 2)) 0;
    }

    @include layout-1440() {
        padding: 0 to-rem(calc(($un-1440 - $grid-max-area-1440) / 2)) 0;
    }

    @include layout-1680() {
        padding: 0 to-rem(calc(($un-1680 - $grid-max-area-1680) / 2)) 0;
    }

    @include layout-1920() {
        padding: 0 to-rem(calc(($un-1920 - $grid-max-area-1920) / 2)) 0;
    }

    @include layout-2560() {
        padding: 0 to-rem(calc(($un-2560 - $grid-max-area-2560) / 2)) 0;
    }

    @include layout-3840() {
        padding: 0 to-rem(calc(($un-3840 - $grid-max-area-3840) / 2)) 0;
    }

    @include layout-5120() {
        padding: 0 to-rem(calc(($un-5120 - $grid-max-area-5120) / 2)) 0;
    }

    @include layout-7680() {
        padding: 0 to-rem(calc(($un-7680 - $grid-max-area-7680) / 2)) 0;
    }
}

%VERTICAL-AREA-CONTAINER {
    @include layout-320() {
        padding: to-rem(calc(($un-320 - $grid-max-area-320) / 2)) 0;
    }

    @include layout-375() {
        padding: to-rem(calc(($un-375 - $grid-max-area-375) / 2)) 0;
    }

    @include layout-414() {
        padding: to-rem(calc(($un-414 - $grid-max-area-414) / 2)) 0;
    }

    @include layout-540() {
        padding: to-rem(calc(($un-540 - $grid-max-area-540) / 2)) 0;
    }

    @include layout-768() {
        padding: to-rem(calc(($un-768 - $grid-max-area-768) / 2)) 0;
    }

    @include layout-960() {
        padding: to-rem(calc(($un-960 - $grid-max-area-960) / 2)) 0;
    }

    @include layout-1024() {
        padding: to-rem(calc(($un-1024 - $grid-max-area-1024) / 2)) 0;
    }

    @include layout-1280() {
        padding: to-rem(calc(($un-1280 - $grid-max-area-1280) / 2)) 0;
    }

    @include layout-1440() {
        padding: to-rem(calc(($un-1440 - $grid-max-area-1440) / 2)) 0;
    }

    @include layout-1680() {
        padding: to-rem(calc(($un-1680 - $grid-max-area-1680) / 2)) 0;
    }

    @include layout-1920() {
        padding: to-rem(calc(($un-1920 - $grid-max-area-1920) / 2)) 0;
    }

    @include layout-2560() {
        padding: to-rem(calc(($un-2560 - $grid-max-area-2560) / 2)) 0;
    }

    @include layout-3840() {
        padding: to-rem(calc(($un-3840 - $grid-max-area-3840) / 2)) 0;
    }

    @include layout-5120() {
        padding: to-rem(calc(($un-5120 - $grid-max-area-5120) / 2)) 0;
    }

    @include layout-7680() {
        padding: to-rem(calc(($un-7680 - $grid-max-area-7680) / 2)) 0;
    }
}

%CREATE-NAVBAR-SPACING {
    box-sizing: border-box;
    width: 100%;
    height: 100%;

    &::before,
    &:after {
        // @extend .ttl-md;

        content: '\00a0';
        display: block;
        position: relative;
        width: 100%;
        height: 100%;
        padding-top: to-rem($F9);
        padding-bottom: to-rem($F9);
        background-color: var(--white);

        @include layout-1024 {
            padding-top: to-rem($F10);
            padding-bottom: to-rem($F10);
        }
    }
}

%PAGE-SUBTITLE {
    //  @extend .txt-md;

    padding: 0 0 to-rem($F8);
    color: var(--grey-3);

    @include layout-768() {
        padding: 0 to-rem($F12) to-rem($F8) 0;
    }
}
