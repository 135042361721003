.generic-button {
    @extend .txt-sm;

    position: relative;
    transition: color 0.5s ease-in-out;
    color: var(--grey-3);
    border: 1px solid var(--grey-2);
    padding: to-rem($F6) to-rem($F4);
    box-shadow: 0 0 0 0 transparent;
    display: block;
    box-sizing: border-box;
    cursor: pointer;

    &:hover,
    &:focus {
        color: var(--black);
        border-color: var(--grey-2);

        .sending & {
            color: var(--grey-3);
        }

        &::after {
            opacity: 1;

            .sending & {
                opacity: 0;
            }
        }
    }

    .sending & {
        pointer-events: none;
        cursor: wait;
    }

    &::after {
        // Use after to transition shadow,
        // for faster performance, avoid transitioning box-shadows

        transition: opacity 0.3s ease-in;
        content: '';
        display: block;
        position: absolute;
        inset: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        box-shadow: inset to-rem($F2) to-rem($F2) to-rem($F5) 0 var(--grey-1),
            inset to-rem(-$F2) to-rem(-$F2) to-rem($F5) 0 var(--grey-2);
    }
}
