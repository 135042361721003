@import '_variables';
@import '_mixins';
@import '_fonts';
@import '_placeholders';

.tax-table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    margin-top: to-rem(20);
    font-size: to-rem(16);

    &--wrapper {
        position: relative;
        width: 100%;
        overflow-x: auto;
    }

    thead {
        text-transform: uppercase;
        text-align: right;
        vertical-align: top;

        th:not(:first-of-type) {
            border: 1px solid;
            padding: 3px 5px;
            background-color: rgb(213, 255, 245);
        }
    }

    tbody {
        vertical-align: bottom;

        tr:nth-last-of-type(1) {
            background-color: rgb(251, 255, 213);
        }
        tr:nth-last-of-type(2) {
            background-color: rgb(255, 213, 248);
        }

        td:first-of-type {
            writing-mode: vertical-rl;
            text-orientation: mixed;
            font-size: to-rem(14);
            padding: to-rem(8) to-rem(4);
        }

        td {
            text-align: right;
            border: to-rem(1) solid;
            padding: 0 to-rem(8px);
        }

        td:last-of-type {
            font-weight: 700;
        }

    }

    tfoot {
        font-size: to-rem(14);

        td {
            padding: to-rem(4) 0;
        }

        tr:first-of-type > td {
            padding-top: to-rem(20);
        }

    }
}
